export type Funnel =
  | 'main'
  | 'cortisol'
  | 'pcos'
  | 'inflammation'
  | 'report'
  | 'challenge'
  | 'cortisol-bf'
  | 'generic'
  | 'cortisol-xmas'
  | 'cortisol-ny'
  | 'trial'
  | 'generic-plan'
  | 'menopause'
  | 'liver'
  | 'cortisol-report'
  | 'cortisol-test';

export const FUNNELS: Record<Funnel, Funnel> = {
  main: 'main',
  cortisol: 'cortisol',
  pcos: 'pcos',
  inflammation: 'inflammation',
  report: 'report',
  challenge: 'challenge',
  'cortisol-bf': 'cortisol-bf',
  'cortisol-xmas': 'cortisol-xmas',
  'cortisol-ny': 'cortisol-ny',
  generic: 'generic',
  'generic-plan': 'generic-plan',
  trial: 'trial',
  menopause: 'menopause',
  liver: 'liver',
  'cortisol-report': 'cortisol-report',
  'cortisol-test': 'cortisol-test',
};

export const GENERIC_FUNNELS_LIST = ['generic', 'generic-plan'];
