import { Funnel } from '~/constants';

const DEFAULT_DATASET_ID = '245623751428036';

const FUNNEL_DATESET_ID_MAP: Partial<Record<Funnel, string>> = {
  trial: '2207253949708929',
  report: '605797599079117',
  menopause: '605797599079117',
  liver: '1826683501204289',
  'cortisol-report': '1826683501204289',
  'cortisol-test': '1162940952216991',
};

export const getMetaDatasetId = (funnel: Funnel, pathname: string) => {
  if (pathname === '/articles/how-i-healed-my-liver-report') {
    return FUNNEL_DATESET_ID_MAP.report;
  } else if (
    [
      '/articles/i-have-my-life-back-liver-report',
      '/articles/from-overweight-and-exhausted-to-full-of-life-liver-report',
    ].includes(pathname)
  ) {
    return FUNNEL_DATESET_ID_MAP.liver;
  }

  return FUNNEL_DATESET_ID_MAP[funnel] || DEFAULT_DATASET_ID;
};
